<template>
  <div>
    <Header></Header>
    <div class="mainwarp">
      <left></left>
      <div class="centerwarp">
        <router-view></router-view>
      </div>
      <right></right>
    </div>
    <Footer></Footer>
  </div>


</template>

<script>
import '@/assets/css/afrontcommon.css'
import '@/assets/css/afrontindex.css'
import '@/assets/css/afrontmedia.css'
import Left from "@/views/afront/Left.vue";
import Right from "@/views/afront/Right.vue";
import Footer from "@/views/afront/Footer.vue";
import Header from "@/views/afront/Header.vue";
import BScroll from "better-scroll";
import MouseWheel from '@better-scroll/mouse-wheel'
import ScrollBar from '@better-scroll/scroll-bar'

BScroll.use(MouseWheel)
BScroll.use(ScrollBar)
export default {
  name: "AFront",
  components: {Header, Footer, Right, Left},
  methods: {
    initScroll() {
      let cw = document.querySelector(".centerwarp");
      const scroll1 = new BScroll(cw, {
        click: true, freeScroll: true, mouseWheel: true,
        scrollbar: {
          fade: this.$store.state.ismobile,
          interactive: true,
          scrollbarTrackClickable: true
        }
      })

      let lw = document.querySelector(".leftwarp");
      const scroll2 = new BScroll(lw, {
        click: true, freeScroll: true, mouseWheel: true,
        scrollbar: {
          fade: this.$store.state.ismobile,
          interactive: true,
          scrollbarTrackClickable: true
        }
      })

      let rw = document.querySelector(".rightwarp");
      const scroll3 = new BScroll(rw, {
        click: true, freeScroll: true, mouseWheel: true,
        scrollbar: {
          fade: this.$store.state.ismobile,
          interactive: true,
          scrollbarTrackClickable: true
        }
      })
    },
    browser() {
      let u = navigator.userAgent
      let app = navigator.appVersion
      return {         //移动终端浏览器版本信息
        trident: u.indexOf('Trident') > -1, //IE
        presto: u.indexOf('Presto') > -1, //opera
        webKit: u.indexOf('AppleWebKit') > -1, //Apple,Google
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //FireFox
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //
        iPhone: u.indexOf('iPhone') > -1, //
        iPad: u.indexOf('iPad') > -1, //
        webApp: u.indexOf('Safari') == -1//
      }
    }
  },
  created() {
    this.$store.state.ismobile = this.browser().mobile
    setTimeout(() => {
      this.initScroll()
    }, 500);
  }
}
</script>
<style scoped>

</style>